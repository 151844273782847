<template>
    <div>
        <loading
            :active="loading"
            is-full-screen
            spinner="bar-fade-scale"
            color="#6495ED"
        />
        <div class="filter-container">
            <div class="left-content"></div>
            <div class="right-content">
                <b-button v-if="openAddTab" variant="light" @click="goBack">
                    <i class="icon icon-keyboard icon-rotate-180"></i> Назад к списку
                </b-button>
                <template v-if="editAccess">
                    <b-button v-if="openAddTab" variant="success" @click="saveDataBtn">Сохранить</b-button>
                </template>
            </div>
        </div>
        <c-breadcrumbs :curYear="curYear" :yearInPeriod="yearInPeriod" :curAbp="curAbp" :curGu="curGu" :curDataType="curDataType" :curVersion="curVersion" :curPrg="curPrg"></c-breadcrumbs>
        <div class="inner-container">
            <b-tabs class="tabs-in-tabs">

                <b-tab title = "основные сведения">
                    <div class="explanatory-note-form form-container p-0">
                        <template v-for="(descItem, descItemIndx) of descriptionArr">
                            <div :key="descItemIndx">
                                <div class="enf-title">{{ descItem.name_ru }}:</div>
                                <b-row class="enf-row">
                                    <b-col cols="12" md="6">
                                        <b-form-group class="enf-form-group" label-cols="3">
                                            <template #label>
                                                {{ description_kk }}:
                                            </template>
                                            <div v-bind:class="[allowable(descItem.nameKk) ? descriptionNotEmpty : descriptionEmpty]" style="text-align: justify"><span
                                                class="enf-placeholder" v-if="!descItem.nameKk">Введите описание {{ description_kk }}</span>
                                                <template v-if="descItem.nameKk.length<200">{{ descItem.nameKk }}</template>
                                                <template v-else>{{ descItem.nameKk.slice(0, 200) }}...</template>
                                            </div>
                                            <template v-if="editAccess">
                                                <i class="icon icon-pencil-edit" @click="editDescription('kk', true, descItem, 'desc')"></i>
                                            </template>
                                            <template v-else>
                                                <i class="icon icon-eye-open" @click="editDescription('kk', true, descItem, 'desc')"></i>
                                            </template>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="12" md="6">
                                        <b-form-group class="enf-form-group" label-cols="3">
                                            <template #label>
                                                {{ description_ru }}:
                                            </template>
                                            <div  v-bind:class="[allowable(descItem.nameRu) ? descriptionNotEmpty : descriptionEmpty]" style="text-align: justify"><span
                                                class="enf-placeholder" v-if="!descItem.nameRu">Введите описание {{ description_ru }}</span>
                                                <template v-if="descItem.nameRu.length<200">{{ descItem.nameRu }}</template>
                                                <template v-else>{{ descItem.nameRu.slice(0, 200) }}...</template>
                                            </div>
                                            <template v-if="editAccess">
                                                <i class="icon icon-pencil-edit" @click="editDescription('ru', true, descItem, 'desc')"></i>
                                            </template>
                                            <template v-else>
                                                <i class="icon icon-eye-open" @click="editDescription('ru', true, descItem, 'desc')"></i>
                                            </template>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </div>
                        </template>
                    </div>
                </b-tab>

                <b-tab title = "цели бп и кр">
                    <ExplanatoryNoteAddTable :curYear="curYear" :yearInPeriod="yearInPeriod" :curAbp="curAbp" :curGu="curGu" :curRegion="curRegion" :curDataType="curDataType" :curVersion="curVersion" :prgItems="prgItems" :userId="userId" :needSave="needSave" :editAccess="editAccess" @needSaved="needSaved" @indicatorsArr="indicatorsArr"/>
                </b-tab>

                <b-tab title = "расходы">
                    <div class="explanatory-note-form form-container p-0">
                        <div class="pprClass">
                            <template v-for="(pprItem, pprItemIndx) of pprListArr">
                                <div :key="'pprItemIndx'+pprItemIndx">
                                    <div class="enf-title">{{setNulls(pprItem.ppr, 3)}} - {{pprItem.name_ru}}: {{pprItem.value.toLocaleString()}} <template v-if="pprItem.value">тыс. тенге</template>
                                    </div>
                                    <b-row class="enf-row">
                                        <b-col cols="12" md="6">
                                            <b-form-group class="enf-form-group" label-cols="3">
                                                <template #label>
                                                    {{ description_kk }}:
                                                </template>
                                                <div  v-bind:class="[allowable(pprItem.nameKk) ? descriptionNotEmpty : descriptionEmpty]">
                                                    <span class="enf-placeholder" v-if="!pprItem.nameKk">Введите описание {{ description_kk }}</span>
                                                    <template v-if="pprItem.nameKk.length<200">{{ pprItem.nameKk }}</template>
                                                    <template v-else>{{ pprItem.nameKk.slice(0, 200) }}...</template>
                                                </div>
                                                <template v-if="editAccess">
                                                    <i class="icon icon-pencil-edit" @click="editDescription('kk', true, pprItem, 'ppr')"></i>
                                                </template>
                                                <template v-else>
                                                    <i class="icon icon-eye-open" @click="editDescription('kk', true, pprItem, 'ppr')"></i>
                                                </template>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12" md="6">
                                            <b-form-group class="enf-form-group" label-cols="3">
                                                <template #label>
                                                    {{ description_ru }}:
                                                </template>
                                                <div v-bind:class="[allowable(pprItem.nameRu) ? descriptionNotEmpty : descriptionEmpty]"><span
                                                    class="enf-placeholder" v-if="!pprItem.nameRu">Введите описание {{ description_ru }}</span>
                                                    <template v-if="pprItem.nameRu.length<200">{{ pprItem.nameRu }}</template>
                                                    <template v-else>{{ pprItem.nameRu.slice(0, 200) }}...</template>
                                                </div>
                                                <template v-if="editAccess">
                                                    <i class="icon icon-pencil-edit" @click="editDescription('ru', true, pprItem, 'ppr')"></i>
                                                </template>
                                                <template v-else>
                                                    <i class="icon icon-eye-open" @click="editDescription('ru', true, pprItem, 'ppr')"></i>
                                                </template>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </div>
                            </template>
                        </div>
                    </div>
                </b-tab>

                <div class="enf-top-info" v-if="editAccess && isFirstYear">
                    <div class="duplicate-radio">
                        <span class="enf-label">Дублировать на следующие года:</span>
                        <b-form-radio-group id="radio-slots" v-model="isDuplicate">
                            <b-form-radio value="true">Да</b-form-radio>
                            <b-form-radio value="false">Нет</b-form-radio>
                        </b-form-radio-group>
                    </div>
                </div>
            </b-tabs>
        </div>
        <b-modal
            v-model="modalVisible"
            modal-class="enf-modal"
            size="lg"
            centered
            :title="modalTitle"
            hide-footer
        >
            <b-form-group>
                <template #label>{{ description_part }}:</template>
                <template v-if="editAccess">
                    <b-form-textarea placeholder="введите описание" v-model="descriptionArea" style="text-align: justify"></b-form-textarea>
                </template>
                <template v-else>
                    <b-form-textarea readonly v-model="descriptionArea" style="text-align: justify"></b-form-textarea>
                </template>
            </b-form-group>
            <template v-if="editAccess">
                <b-button variant="primary" @click="addDescriptionForm">Сохранить</b-button>
            </template>
            <b-button variant="light" @click="closeDescriptionForm">Отменить</b-button>
        </b-modal>
    </div>
</template>

<script>
import ExplanatoryNoteAddTable from './ExplanatoryNoteAddTable.vue';
import CBreadCrumbs from './table-dri-breadcrumbs.vue';
import VueElementLoading from "vue-element-loading";
import {makeToast} from "@/modules/budget/bip/bip-types";
export default {
    name: "ExplanatoryNoteTabs",
    components: {
        'c-breadcrumbs': CBreadCrumbs,
        'loading': VueElementLoading,
        ExplanatoryNoteAddTable
    },
    props: {
        openAddTab: {
            type: Boolean,
            required: true
        },
        moduleTabSwitch: {
            type: Boolean,
            required: true
        },
        prgItems: {
            type: Object,
            required: true
        },
        curYear: {
            type: Object,
            required: false,
            default: () => {}
        },
        yearInPeriod: {
            type: Number,
            required: false,
            default: null
        },
        curRegion: {
            type: String,
            required: true
        },
        curAbp: {
            type: Object,
            required: false,
            default: () => {}
        },
        curGu: {
            type: Object,
            required: false,
            default: () => {}
        },
        curDataType: {
            type: Object,
            required: false,
            default: () => {}
        },
        curVersion: {
            type: Object,
            required: false,
            default: () => {}
        },
        userId: {
            type: String,
            required: false,
            default: () => {}
        }
    },
    async created() {
        this.curPrg = this.prgItems;
    },
    data() {
        return {
            description_ru: 'на русском',
            description_kk: 'на казахском',
            descriptionEmpty: 'text-area-block-empty',
            descriptionNotEmpty: 'text-area-block',
            description_part: '',
            descriptionArea: '',
            modalTitle: '',
            modalVisible: false,
            isDuplicate: false,
            loading: false,
            needSave: false,
            editAccess: false,
            isFirstYear: false,
            curPrg: '',
            error: '',
            ru: 'ru',
            kk: 'kk',
            accessLevel: 1,
            descriptionArr: [
                {indx: 1, name_ru: 'Описание достигнутых показателей / результатов за отчетный финансовый год', title_indicator: 'finance_result', nameKk: '', nameRu: '', id: ''},
                {indx: 2, name_ru: 'Описание текущей ситуации и имеющихся проблем', title_indicator: 'current_situation', nameKk: '', nameRu: '', id: ''},
                {indx: 3, name_ru: 'Описание путей улучшения ситуации и решения проблем', title_indicator: 'solve_decision', nameKk: '', nameRu: '', id: ''},
                {indx: 4, name_ru: 'Описание путей достижения планируемы целевых индикаторов', title_indicator: 'target_indicator_achievement', nameKk: '', nameRu: '', id: ''}
            ],
            descriptionItems: {
                indicator: null,
                descrId: null,
                lang: null
            },
            requestNoteDescriptionArr: [],
            stored_data: [],
            pprListArr: []
        }
    },
    async mounted() {
        await this.getPprList();
        await this.getRequestNoteList();
        this.accessLevel = await this.getUserAccess(this.userId);
        this.editAccess = this.getRole(this.accessLevel);
        // this.editAccess = this.getRole(1);
        this.isFirstYear = parseInt(this.curYear.year) === parseInt(this.yearInPeriod);
        // console.log('this.editAccess: ' + JSON.stringify(this.editAccess));
    },
    methods: {
        getRole(accessLevel){
            if (accessLevel === 1){
                return false;
            } else if (accessLevel === 2 || accessLevel === 3){
                return  true;
            }
            return false;
        },
        needSaved(){
            this.needSave = false;
        },
        allowable(descItem){
            if (descItem.length > 0 && this.editAccess){
                return true;
            } else if (!this.editAccess){
                return true;
            }
            return false;
        },
        indicatorsArr(indicatorsArr){
            const mainObject = this.generateDataForSaving();
            const targetIndicator = createTargetIndicatorObj(mainObject.budgetRequestNoteEntityDto, indicatorsArr);
            mainObject.budgetRequestNoteTargetIndicatorEntityDto = targetIndicator;
            const isAnyEmptyField = checkForEmptyField(mainObject);
            if (!isAnyEmptyField) {
                this.saveData(mainObject);
            } else {
                this.makeToast('Предупреждение', 'Необходимо заполнить все обязательные поля');
            }

            function createTargetIndicatorObj(budgetRequestNote, indicatorsArr){
                const arr = [];
                if (indicatorsArr.length > 0 && Object.keys(budgetRequestNote).length !== 0) {
                    for (const indicator of indicatorsArr){
                        const targetIndicator = {
                            id: null,
                            abp: budgetRequestNote.abp,
                            prg: budgetRequestNote.prg,
                            gu: budgetRequestNote.gu,
                            dataTypeId: budgetRequestNote.dataTypeId,
                            year: budgetRequestNote.year,
                            curYear: budgetRequestNote.curYear,
                            region: budgetRequestNote.region,
                            variant: budgetRequestNote.variant,
                            forecastId: indicator.forecastId,
                            programId: indicator.programId,
                            goalId: indicator.goalId,
                            indicatorId: indicator.indicatorId,
                            nameKk: indicator.nameKk,
                            nameRu: indicator.nameRu,
                            userId: budgetRequestNote.userId
                        }
                        arr.push(targetIndicator);
                    }
                }
                return arr;
            }

            function checkForEmptyField(mainObject){
                const arrWithEmptyFields = [];
                if (mainObject.budgetRequestNoteDescription.length > 0 && Object.keys(mainObject.budgetRequestNoteEntityDto).length !== 0 && mainObject.budgetRequestNoteTargetIndicatorEntityDto.length > 0){
                    for (const noteDescription of mainObject.budgetRequestNoteDescription){
                        if (noteDescription.nameKk==='' || noteDescription.nameRu===''){
                            arrWithEmptyFields.push('empty');
                        }
                    }
                    for (const targetIndicator of mainObject.budgetRequestNoteTargetIndicatorEntityDto){
                        if (targetIndicator.nameKk==='' || targetIndicator.nameRu===''){
                            arrWithEmptyFields.push('empty');
                        }
                    }
                    if (arrWithEmptyFields.length===0){
                        return false;
                    }
                }
                return true;
            }
        },
        goBack(){
            this.$emit('moduleTabSwitches', true);
        },  //---При нажатии на кнопку "НАЗАД К СПИСКУ"
        saveDataBtn() {
            this.needSave = true;
        },

        async saveData(mainObject) {
            this.loading = true;
            try {
                const url = '/api/explanatory-note/budget-request-note/add';
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(mainObject) // массив будет направлен на kotlin
                });
                const result = await response.json();
                if ((response.status === 200) && (result === true)) {
                    this.loading = false;
                    await this.getRequestNoteList();
                    this.makeToast('Сообщение', 'Данные сохранены', '');
                } else {
                    this.loading = false;
                    this.makeToast('Предупреждение', 'Ошибка сохранения данных');
                }
            } catch {
                this.loading = false;
                this.makeToast('Предупреждение', 'Ошибка сохранения данных');
            }
        },  //---Основной метод для сохранения записей в БД

        generateDataForSaving(){
            return {
                budgetRequestNoteDescription: this.generateNoteDescriptionArr(),
                budgetRequestNoteEntityDto: this.generateRequestNote(),
                budgetRequestNoteTargetIndicatorEntityDto: '',
                duplicate: this.isDuplicate,
                startYear: !this.isDuplicate ? '' : this.yearInPeriod,
                endYear: this.isDuplicate ? this.yearInPeriod+2: ''
            }
        },

        generateNoteDescriptionArr(){
            const descriptionArr = [];
            if (this.descriptionArr.length > 0) {
                for (const description of this.descriptionArr){
                    const descriptionObj = {
                        id: null,
                        nameKk: description.nameKk,
                        nameRu: description.nameRu,
                        titleIndicator: description.title_indicator,
                        titleSorting: description.indx,
                        ppr: '',
                        userId: this.userId
                    }
                    descriptionArr.push(descriptionObj);
                }
            }
            if (this.pprListArr.length>0){
                for (const pprItem of this.pprListArr){
                    const descriptionPprObj = {
                        id: null,
                        nameKk: pprItem.nameKk,
                        nameRu: pprItem.nameRu,
                        titleIndicator: pprItem.title_indicator,
                        titleSorting: pprItem.indx,
                        ppr: pprItem.ppr,
                        userId: this.userId
                    }
                    descriptionArr.push(descriptionPprObj);
                }
            }
            return descriptionArr;
        },  //---Генерация массива для "budgetRequestNoteDescription"

        generateRequestNote(){
            const requestNoteObject = this.requestNoteObject();
            requestNoteObject.abp = this.curAbp.abp;
            requestNoteObject.prg = this.prgItems.prg;
            requestNoteObject.gu = this.curGu.code;
            requestNoteObject.dataTypeId = this.curDataType.code;
            requestNoteObject.year = this.yearInPeriod;
            requestNoteObject.curYear = this.curYear.year;
            requestNoteObject.region = this.curRegion;
            requestNoteObject.variant = this.curVersion.variant_uuid;
            requestNoteObject.userId = this.userId;
            return requestNoteObject;
        },  //---Генерация объекта для "budgetRequestNoteEntityDto"

        async getRequestNoteList() {
            const requestNoteObj = this.generateRequestNote();
            try {
                let response = [];
                response = await fetch('/api/explanatory-note/budget-request-note/get/'+requestNoteObj.abp+'/'+requestNoteObj.prg+'/'+requestNoteObj.gu+'/'+requestNoteObj.dataTypeId+'/'+requestNoteObj.year+'/'+requestNoteObj.curYear+'/'+requestNoteObj.region+'/'+requestNoteObj.variant);
                response = await response.json();
                if (response) {
                    this.stored_data = response;
                    getListOfRequestDescriptionNotes(response, this.descriptionArr);
                    getListOfRequestDescriptionNotesWithPpr(response, this.pprListArr);
                }
            } catch (error) {
                makeToast(this, 'danger', 'Ошибка загрузки Описаний на казахском и русском языках', error.toString());
            }

            function getListOfRequestDescriptionNotes(stored_data, descriptionArr){
                const arr = [];
                if (stored_data.length>0 && descriptionArr.length>0){
                    for (const description of descriptionArr){
                        for (const storedData of stored_data){
                            if (parseInt(description.indx) === parseInt(storedData.titleSorting) && (storedData.titleIndicator.trim() === description.title_indicator.trim())){
                                description.id = storedData.id;
                                description.nameKk = storedData.nameKk;
                                description.nameRu = storedData.nameRu;
                                arr.push(description);
                            }
                        }
                    }
                }
                return arr;
            }   //---Массив с описаниями получает ранее сохраненные значения из БД

            function getListOfRequestDescriptionNotesWithPpr(pprResponse, pprListArr){
                if (pprResponse.length>0 && pprListArr.length>0){
                    for (const pprRespItem of pprResponse){
                        for (const pprItem of pprListArr){
                            if (parseInt(pprRespItem.abp)===parseInt(pprItem.abp) && parseInt(pprRespItem.prg)===parseInt(pprItem.prg) && parseInt(pprRespItem.ppr)===parseInt(pprItem.ppr) && JSON.parse(pprRespItem.gu)===JSON.parse(pprItem.gu)){
                                pprItem.id = pprRespItem.id;
                                pprItem.nameKk = pprRespItem.nameKk;
                                pprItem.nameRu = pprRespItem.nameRu;
                                pprItem.title_indicator = 'ppr';
                            }
                        }
                    }
                }
            }
        },  //---Получить список "budgetRequestNoteDescription" и "budgetRequestNote"

        async getPprList() {
            const requestNoteObj = this.generateRequestNote();
            try {
                let response = [];
                response = await fetch('/api/explanatory-note/budget-request-form-total/get_ppr/'+requestNoteObj.abp+'/'+requestNoteObj.prg+'/'+requestNoteObj.gu+'/'+requestNoteObj.year+'/'+requestNoteObj.variant);
                response = await response.json();
                if (response) {
                    setAdditionalValuesToArr(this.pprListArr, response)
                }
            } catch (error) {
                makeToast(this, 'danger', 'Ошибка загрузки "Подпрограмм"', error.toString());
            }

            function setAdditionalValuesToArr(pprListArr, response){
                if (response.length>0){
                    let indx = 0;
                    for (const resp of response){
                        resp.indx = ++indx;
                        resp.nameKk = '';
                        resp.nameRu = '';
                        pprListArr.push(resp);
                    }
                }
            }
        },

        setNulls(element, size){
            const el = element.toString();
            let res = '';
            if (el.length>0 && el.length<size){
                const diffVal = size>el.length ? size-el.length : el.length;
                for (let i = 0; i < diffVal; i++) {
                    res += '0';
                }
                res = res+el;
            }
            return res;
        },

        closeDescriptionForm(){
            this.modalVisible = false;
            this.descriptionArea = '';
        },

        editDescription(lang, show, descrItem, field){
            if (show){
                this.modalVisible = show;
                const desc = 'desc';
                const ppr = 'ppr';
                if (field === desc) {
                    if (lang === this.ru) {
                        this.description_part = getDescription(this.description_ru);
                        this.descriptionItems.descrId = descrItem.indx;
                        this.descriptionItems.indicator = descrItem.title_indicator;
                        this.descriptionItems.lang = this.ru;
                        this.descriptionItems.title = desc;
                        this.descriptionArea = descrItem.nameRu;
                    } else if (lang === this.kk) {
                        this.description_part = getDescription(this.description_kk);
                        this.descriptionItems.descrId = descrItem.indx;
                        this.descriptionItems.indicator = descrItem.title_indicator;
                        this.descriptionItems.lang = this.kk;
                        this.descriptionItems.title = desc;
                        this.descriptionArea = descrItem.nameKk;
                    }
                    this.modalTitle = descrItem.name_ru;
                } else if (field === ppr){
                    if (lang === this.kk) {
                        this.description_part = getDescription(this.description_kk);
                        this.descriptionItems.abp = descrItem.abp;
                        this.descriptionItems.prg = descrItem.prg;
                        this.descriptionItems.ppr = descrItem.ppr;
                        this.descriptionItems.gu = descrItem.gu;
                        this.descriptionItems.lang = this.kk;
                        this.descriptionItems.title = ppr;
                        this.descriptionItems.title_indicator = 'ppr';
                        this.descriptionArea = descrItem.nameKk;
                    } else if (lang === this.ru) {
                        this.description_part = getDescription(this.description_ru);
                        this.descriptionItems.abp = descrItem.abp;
                        this.descriptionItems.prg = descrItem.prg;
                        this.descriptionItems.ppr = descrItem.ppr;
                        this.descriptionItems.gu = descrItem.gu;
                        this.descriptionItems.lang = this.ru;
                        this.descriptionItems.title = ppr;
                        this.descriptionItems.title_indicator = 'ppr';
                        this.descriptionArea = descrItem.nameRu;
                    }
                    this.modalTitle = descrItem.name_ru;
                }
            }

            function getDescription(word){
                const firstPart = word.slice(0, 1);
                const secondPart = word.slice(1, word.length);
                return (firstPart.toUpperCase())+secondPart;
            }
        },  //---Открытие модального окна для каждого описания

        requestNoteDescriptionObject(){
            return {
                id: null,
                nameKk: '',
                nameRu: '',
                titleIndicator: '',
                titleSorting: '',
                abp: '',
                prg: '',
                ppr: '',
                gu: '',
                lang: ''
            }
        },

        requestNoteObject(){
            return {
                id: null,
                abp: '',
                prg: '',
                gu: '',
                dataTypeId: '',
                year: '',
                curYear: '',
                region: '',
                variant: '',
                userId: '',
                requestNoteDescriptionId: ''
            }
        },

        addDescriptionForm(){
            const language = {
                'kk': this.kk,
                'ru': this.ru
            }
            const noteDescription = this.requestNoteDescriptionObject();
            if (this.descriptionItems.title === 'desc') {
                if (this.descriptionItems.lang === language.kk) {
                    noteDescription.nameKk = this.descriptionArea;
                    noteDescription.lang = language.kk;
                }
                if (this.descriptionItems.lang === language.ru) {
                    noteDescription.nameRu = this.descriptionArea;
                    noteDescription.lang = language.ru;
                }
                noteDescription.titleIndicator = this.descriptionItems.indicator;
                noteDescription.titleSorting = this.descriptionItems.descrId;
                this.setValuesToDescriptionArr(noteDescription);
            } else if (this.descriptionItems.title === 'ppr'){
                if (this.descriptionItems.lang === language.kk) {
                    noteDescription.nameKk = this.descriptionArea;
                    noteDescription.lang = language.kk;
                }
                if (this.descriptionItems.lang === language.ru) {
                    noteDescription.nameRu = this.descriptionArea;
                    noteDescription.lang = language.ru;
                }
                noteDescription.titleIndicator = this.descriptionItems.title_indicator;
                noteDescription.abp = this.descriptionItems.abp;
                noteDescription.prg = this.descriptionItems.prg;
                noteDescription.ppr = this.descriptionItems.ppr;
                noteDescription.gu = this.descriptionItems.gu;
                setValuesToPpr(this.pprListArr, noteDescription, language);
            }
            this.closeDescriptionForm();

            function setValuesToPpr(pprListArr, noteDescription, language){
                if (pprListArr.length > 0 && noteDescription){
                    for (const ppr of pprListArr){
                        if (ppr.abp === noteDescription.abp && ppr.prg === noteDescription.prg && ppr.ppr === noteDescription.ppr && ppr.gu === noteDescription.gu){
                            if (noteDescription.lang === language.kk){
                                ppr.nameKk = noteDescription.nameKk;
                                ppr.title_indicator = noteDescription.titleIndicator;
                            } else if (noteDescription.lang === language.ru){
                                ppr.nameRu = noteDescription.nameRu;
                                ppr.title_indicator = noteDescription.titleIndicator;
                            }
                        }
                    }
                }
            }
        },  //---Добавление текста для каждой формы описания

        setValuesToDescriptionArr(noteDescription){
            if (noteDescription && this.descriptionArr.length > 0){
                const indx = getArrIndex(noteDescription.titleSorting);
                if (noteDescription.lang === this.kk) {
                    this.descriptionArr[indx].nameKk = noteDescription.nameKk;
                } else if (noteDescription.lang === this.ru) {
                    this.descriptionArr[indx].nameRu = noteDescription.nameRu;
                }
            }

            function getArrIndex(indx){
                switch (indx){
                    case 1:
                        return 0;
                    case 2:
                        return 1;
                    case 3:
                        return 2;
                    case 4:
                        return 3;
                }
            }
        },   //---При добавлении текста из модального окна, данные устанавливаются в нужной ячейке

        async getUserAccess(userId){
            // console.log('userId: ' + JSON.stringify(userId));
            let accessLevel = '';
            const modules = '004.002.010';
            const url = '/api-py/user-modules/'+userId;
            const response = await fetch(url);
            const items = await response.json();
            // console.log('items: ' + JSON.stringify(items));
            const access = getAccess(items, modules);
            accessLevel = access.access_level
            function getAccess(items, modules){
                const res = {};
                if (items.length > 0){
                    for (const item of items){
                        if (item.modules === modules){
                            return item;
                        }
                    }
                }
                return res;
            }
            return accessLevel;
        },

        makeToast(title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                autoHideDelay: 5000,
                appendToast: true
            });
        } // сообщение с ошибкой
    }
}
</script>

<style scoped>
    .actions-tab.in-tab{
        position: fixed;
        top: 331px;
        z-index: 1008;
        -webkit-transition: 0.2s all ease;
        transition: 0.2s all ease;
    }

    .filter-btn-actions{
        text-align: right;
        border-top: 1px solid #E2E5EB;
    }
    .enf-top-info{
        justify-content: end;
    }
    .pprClass{
        padding-top: 30px;
    }
</style>