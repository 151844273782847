<template>
    <div class="inner-container explanatory-note">
        <loading
            :active="loading"
            is-full-screen
            spinner="bar-fade-scale"
            color="#6495ED"
        />
        <div v-if="!bpMode" class="section-title budget"><span>Бюджетное планирование</span></div>
        <div v-if="!bpMode" class="section-subtitle budget">
            <i class="icon icon-grid"></i>
            <span>Бюджетные заявки<c-budg-form-lst :curFormSelect="curFormSelect"/></span>
            <div v-if="moduleTabSwitch && !bpMode" class="actions-tab in-tab" id="bp-info-tab" style="top: 100px">
                <!-- Инструкцию модуля -->
                <b-dropdown variant="link" class="info" right toggle-class="text-decoration-none" no-caret>
                    <template #button-content id="info-dropdown">
                        <div class="btn-download">
                            <div>
                                <span class="left">
                                    <i class="icon icon-info"></i>
                                </span>
                                <i class="icon icon-keyboard"></i>
                            </div>
                        </div>
                    </template>
                    <b-dropdown-text>
                        <div class="info-button"></div>
                        <div class="info-text"></div>
                    </b-dropdown-text>
                </b-dropdown>
                <!-- Скачивание отчетов -->
                <b-dropdown variant="link" right toggle-class="text-decoration-none" no-caret>
                    <template #button-content>
                        <div class="btn-download">
                            <div>
                                    <span class="left">
                                        <i class="icon icon-download"></i> Скачать
                                    </span>
                                <i class="icon icon-keyboard"></i>
                            </div>
                        </div>
                    </template>
                    <b-dropdown-item @click="firstReport">Отчет</b-dropdown-item>
                </b-dropdown>
            </div>
        </div>
        <div v-if="moduleTabSwitch" class="filter-area">
            <div class="filter-container">
                <div class="left-content">
                    <b-dropdown class="filter-dropdown" variant="default" ref="drop">
                        <template #button-content>
                            <span class="lc"><i class="icon icon-filter"></i> Фильтр</span><span class="rc"><i class="icon icon-keyboard"></i></span>
                        </template>
                        <div>
                            <div class="top-content">
                                <span>Параметры фильтра</span>
                                <i class="icon icon-close" @click="$refs.drop.hide(true)"></i>
                            </div>
                            <!--------------- Плановый период ------------------->
                            <div class="filter-block">
                                <b-form-group label="Плановый период">
                                    <multiselect
                                        :disabled="mainTableOverlay"
                                        v-model="curYear"
                                        track-by="name"
                                        label="name"
                                        placeholder="Выбрать год"
                                        :options="yearPeriods"
                                        :searchable="false"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        @input="chgYearPeriod"
                                        ref="yearPeriodRef"
                                    >
                                    </multiselect>
                                </b-form-group>
                            </div>
                            <!---------------Фильтр по году-------------------->
                            <div class="filter-block">
                                <b-form-group :label="yearItem" class="small">
                                    <multiselect
                                        v-model="yearInPeriod"
                                        :options="yearsArr"
                                        placeholder="Год"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        @input="chgYear"
                                        ref="curYearRef"
                                    />
                                </b-form-group>
                            </div>
                            <!----------------Администратор программ abp------------------->
                            <div class="filter-block">
                                <b-form-group label="Адм. программ">
                                    <multiselect
                                        v-model="curAbp"
                                        track-by="name"
                                        label="name"
                                        placeholder="Выбрать адм. программ"
                                        :options="abpBase"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        @input="chgAbp"
                                        ref="abpRef"
                                    >
                                    </multiselect>
                                </b-form-group>
                            </div>
                            <!----------------Гос.учреждение------------------->
                            <div class="filter-block">
                                <b-form-group label="Гос. учреждение">
                                    <multiselect
                                        v-model="curGu"
                                        track-by="name"
                                        label="name"
                                        placeholder="Выбрать гос. учреждение"
                                        :options="guBase"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        @input="chgInstitution"
                                        ref="guRef"
                                    >
                                    </multiselect>
                                </b-form-group>
                            </div>
                            <!----------------Регион------------------->
                            <div class="filter-block">
                                <b-form-group label="Регион">
                                    <b-form-input type="text" v-model="curRegionVal" readonly></b-form-input>
                                </b-form-group>
                            </div>
                            <!----------------Вид данных------------------->
                            <div class="filter-block">
                                <b-form-group label="Вид данных">
                                    <multiselect
                                        v-model="curDataType"
                                        track-by="name"
                                        label="name"
                                        placeholder="Выбрать вид данных"
                                        :options="dataTypeList"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        @input="chgDataType"
                                        ref="dataTypeRef"
                                    >
                                    </multiselect>
                                </b-form-group>
                            </div>
                            <!----------------Версия бюджета------------------->
                            <div class="filter-block">
                                <b-form-group label="Версия бюджета">
                                    <multiselect
                                        v-model="curVersion"
                                        track-by="name"
                                        label="name"
                                        placeholder="Выбрать версию бюджета"
                                        :options="versionDataTypeList"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        @input="chgVersion"
                                        ref="versionRef"
                                    >
                                    </multiselect>
                                </b-form-group>
                            </div>
                            <div class="filter-block">
                                <b-button variant="success" @click="chgParams" :disabled="isDisabled">Открыть</b-button>
                            </div>
                        </div>
                    </b-dropdown>
                </div>
            </div>
            <c-breadcrumbs :curYear="curYear" :yearInPeriod="yearInPeriod" :curAbp="curAbp" :curGu="curGu" :curDataType="curDataType" :curVersion="curVersion" @openFilterByRef="openFilterByRef"></c-breadcrumbs>
            <div class="table-container">
                <div class="b-table-sticky-header table-responsive-true">
                    <table class="table b-table table-bordered b-table-no-border-collapse">
                        <thead>
                        <tr class="text-center">
                            <th rowspan="2" class="toggle-show">
                                <button type="button" class="btn btn-secondary" @click="collapseAll = !collapseAll;">
                                    <i class="icon icon-chevron-circle" v-if="collapseAll"></i>
                                    <i class="icon icon-chevron-circle icon-rotate-180" v-else></i>
                                </button>
                            </th>
                            <th style="width: 0px;">ФУНКЦИОНАЛЬНАЯ ГРУППА</th>
                            <th>АБП</th>
                            <th>ГУ</th>
                            <th>ПРОГРАММА</th>
                            <th>НАИМЕНОВАНИЕ</th>
                            <th>СТАТУС БЗ</th>
                            <th>СТАТУС ПОСЛЕДНЕГО РЕДАКТИРОВАНИЯ ПЗ</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="(funcItem, funcItemIndx) of funcGrArr">
                            <tr :key="'funcItemIndx'+funcItemIndx" class="table-info">
                                <td class="toggle-show">
                                    <button type="button" class="btn btn-secondary" @click="collapseItems(funcItemIndx)">
                                        <i class="icon icon-chevron-circle" v-if="collapseArr[funcItemIndx]"></i>
                                        <i class="icon icon-chevron-circle icon-rotate-180" v-else></i>
                                    </button>
                                </td>
                                <td colspan="8">{{ funcItem.name }}</td>
                            </tr>
                            <template v-if="!collapseArr[funcItemIndx]">
                                <template v-for="(abpItem, abpItemIndex) of abpListArr">
                                    <template v-if="abpItem!==null && abpItem.gr===funcItem.gr">
                                        <tr :key="`abpItemIndex${abpItemIndex}_${funcItemIndx}`" class="table-info" style="background-color: #ecf7ff">
                                            <td></td>
                                            <td class="toggle-show">
                                                <button type="button" class="btn btn-secondary" @click="collapseItemsSec(abpItemIndex)">
                                                    <i class="icon icon-chevron-circle" v-if="collapseArrSec[abpItemIndex]"></i>
                                                    <i class="icon icon-chevron-circle icon-rotate-180" v-else></i>
                                                </button>
                                            </td>
                                            <td colspan="7"><template v-if="abpItem!==null && abpItem.gr===funcItem.gr">{{ abpItem.name }}</template></td>
                                        </tr>
                                        <template v-if="!collapseArrSec[abpItemIndex]">
                                            <template v-for="(guItem, guItemIndex) of dictGuListArr">
                                                <template v-if="guItem!==null && parseInt(guItem.abp_owner)===parseInt(abpItem.abp)">
                                                    <tr :key="`guItemIndex_${guItemIndex}_${abpItemIndex}_${funcItemIndx}`" style="background-color: rgb(236, 247, 255);">
                                                        <td colspan="2"></td>
                                                        <td class="toggle-show">
                                                            <button type="button" class="btn btn-secondary" @click="collapseItemsThird(guItemIndex)">
                                                                <i class="icon icon-chevron-circle" v-if="collapseArrThird[guItemIndex]"></i>
                                                                <i class="icon icon-chevron-circle icon-rotate-180" v-else></i>
                                                            </button>
                                                        </td>
                                                        <td colspan="6">{{ guItem.name }}</td>
                                                    </tr>
                                                    <template v-if="!collapseArrThird[guItemIndex]">
                                                        <template v-for="(elementItem, elementItemIndex) of programListArr">
                                                            <template v-if="elementItem!==null && parseInt(elementItem.abp)===parseInt(guItem.abp_owner) && elementItem.gr===abpItem.gr && elementItem.pgr === abpItem.pgr && elementItem.abp === abpItem.abp && elementItem.gr === funcItem.gr">
                                                                <tr :key="`targetItemIndx_${guItemIndex}_${abpItemIndex}_${funcItemIndx}_${elementItemIndex}`">
                                                                    <td colspan="4"></td>
                                                                    <td>{{ elementItem.prg }}</td>
                                                                    <td>{{ elementItem.name_ru }}</td>
                                                                    <td>{{ elementItem.agrNameRu }}</td>
                                                                    <td></td>
                                                                    <td style="text-align: center">
                                                                        <b-dropdown id="dropdown-dropleft" dropleft class="more">
                                                                            <template v-slot:button-content>
                                                                                <i class="icon icon-more"></i>
                                                                            </template>
                                                                            <template>
                                                                                <b-dropdown-item @click="editBtn(elementItem)">
                                                                                    Редактировать
                                                                                </b-dropdown-item>
                                                                                <b-dropdown-item @click="deleteClk(elementItem)">
                                                                                    Удалить
                                                                                </b-dropdown-item>
                                                                            </template>
                                                                        </b-dropdown>
                                                                    </td>
                                                                </tr>
                                                            </template>
                                                        </template>
                                                    </template>
                                                </template>
                                            </template>
                                        </template>
                                    </template>
                                </template>
                            </template>
                        </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <c-explanatoryNoteTabs :curYear="curYear" :yearInPeriod="yearInPeriod" :curAbp="curAbp" :curGu="curGu" :curRegion="curRegion" :curDataType="curDataType" :curVersion="curVersion" :openAddTab="openAddTab" :moduleTabSwitch="moduleTabSwitch" :prgItems="prgItems" :userId="userId" @moduleTabSwitches="moduleTabSwitches" v-if="openAddTab"/>
    </div>

</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import Multiselect from 'vue-multiselect';
import CBudgetFormsList from "@/modules/budget-request/components/budget-forms-list";
import {CUR_YEAR} from "@/modules/budget-request/components/js/budgetCurYear";
import CBreadCrumbs from './components/table-dri-breadcrumbs.vue';
import ExplanatoryNoteTabs from './components/ExplanatoryNoteTabs'
import {parseInt} from "lodash";
import VueElementLoading from 'vue-element-loading';

export default {
    name: "initialFormOfNote",
    components: {
        'c-budg-form-lst': CBudgetFormsList,
        'c-breadcrumbs': CBreadCrumbs,
        'c-explanatoryNoteTabs': ExplanatoryNoteTabs,
        'multiselect': Multiselect,
        'loading': VueElementLoading
    },
    created() {
        this.userId = this.$store.getters.user_uuid;
        console.log('this.userId: ' + this.userId);
        this.$watch('collapseAll', this.collapseAllFunc);
    },

    async mounted() {
        this.yearPeriods = this.years();
    },
    data() {
        return {
            bpMode: false,
            loading: false,
            moduleTabSwitch: true,
            isDisabled: true,
            openAddTab: false,
            curFormSelect: '/budget-program',
            yearItem: 'Год',
            addRecords: {
                name_ru: 'Добавить',
                name_en: 'Add',
                name_kk: 'Добавить'
            },
            curYear: {},
            chosenYear: 0,
            yearInPeriod: null,
            curFunGr: null,
            curAbp: null,
            curGu: null,
            curRegion: null,
            curRegionVal: null,
            curDataType: null,
            curVersion: null,
            mainTableOverlay: false,
            userId: null,
            edit: false,
            collapseAll: true,
            prgItems: {},
            collapseArr: [],
            collapseArrSec: [],
            collapseArrThird: [],
            funcGrBase: [],
            yearPeriods: [],
            yearsArr: [],
            funcGr: [],
            abpBase: [],
            abpBaseArr: [],
            fullGuBaseList: [],
            fullFilterArr: [],
            guBase: [],
            regionsList: [],
            dataTypeList: [],
            versionList: [],
            versionDataTypeList: [],
            funcGrArr: [],
            abpListArr: [],
            formTotalArr: [],
            dictGuListArr: [],
            programListArr: [],
            formTotalAgreementArr: [],
            guListPre: [],
            regionListPre: [],
            dataTypeListPre: [],
            variantListPre: [],
            budgetRequestFormTotalArr: []
        }
    },

    methods: {
        firstReport(){
            console.log("First Report");
        },
        years(){
            const year = CUR_YEAR + 1;
            const periodLst = [];
            const startDate = 2021;
            const endDate = year + 2;
            let chosenYear = 0;
            for (let i = endDate; i >= startDate; i--) {
                periodLst.push({ name: `${i} - ${i + 2}`, year: i });
                if (year === i) {
                    this.curYear = { name: `${i} - ${i + 2}`, year: i };
                    chosenYear = this.curYear.year;
                }
            }
            this.listOfYears(chosenYear);
            return periodLst;
        },
        chgYearPeriod() {
            this.listOfYears(this.curYear.year);
            this.curRegion = null;
            this.curDataType = null;
            this.curVersion = null;
            this.curAbp = null;
            this.curGu = null;
            this.curRegionVal = null;
            this.versionList = [];
            this.versionDataTypeList = [];
        },
        chgYear(){
            // this.loadGu(this.yearInPeriod);
            this.curRegion = null;
            this.curDataType = null;
            this.curVersion = null;
            this.curAbp = null;
            this.curGu = null;
            this.curRegionVal = null;
            this.getFilterData(this.curYear.year, this.yearInPeriod, this.userId);
        },
        async getFilterData(curYear, year, userId) {
            this.loading = true;
            try {
                const result = await fetch(`/api/explanatory-note/budget-request-form-total/form-total-filter/${curYear}/${year}/${userId}`)
                    .then(response => response.json());
                if (result) {
                    this.generateFilterList(result);
                    this.chgParams();
                }
            } catch (error) {
                this.makeToast('Ошибка загрузки данных', 'По данному запросу ничего не найдено');
            }
        },
        generateFilterList(mainArr){
            this.budgetRequestFormTotalArr = mainArr.budgetRequestFormTotal;
            this.guListPre = mainArr.guList;
            this.regionListPre = mainArr.regions;
            this.dataTypeListPre = mainArr.dataType;
            this.variantListPre = mainArr.variants;

            this.generateAbpList(mainArr.abpList);
        },

        generateAbpList(abpListArr){
            if (abpListArr.length > 0){
                const abpFromFormTotal = this.getUniqObjects(this.budgetRequestFormTotalArr, 'abp');
                for (const el of abpListArr){
                    for (const uniqAbp of abpFromFormTotal) {
                        if (parseInt(el.abp) === parseInt(uniqAbp.abp)) {
                            this.abpBase.push(this.setNameLang(el, 'abp'));
                        }
                    }
                }
                if (this.abpBase.length>0){
                    this.curAbp = this.abpBase[0];
                    this.generateGuList(this.guListPre);
                    this.generateDataTypeList(this.dataTypeListPre);
                    this.generateVariantList(this.variantListPre);
                    this.chgAbp();
                }
            }
        },

        generateGuList(guListArr){
            if (guListArr.length>0){
                for (const el of guListArr){
                    this.fullGuBaseList.push(this.setNameLang(el, 'code'));
                }
            }
        },

        generateDataTypeList(dataTypeListArr){
            if (dataTypeListArr.length>0){
                for (const el of dataTypeListArr){
                    this.dataTypeList.push(this.setNameLang(el, 'code'));
                }
            }
        },

        generateVariantList(variantArr){
            if (variantArr.length>0){
                for (const el of variantArr){
                    this.versionList.push(this.setNameLang(el, 'version'));
                }
            }
        },

        // -----Функция для очистки массива от дубликатов
        getUniqObjects(arr, param) {
            const filteredObjectArr = arr.reduce((enteredArr, current) => {
                let x = null;
                if (param === 'region'){
                    x = enteredArr.find(item => item.abp === current.abp && item.gu === current.gu && item.region === current.region);
                } else if (param === 'dataType'){
                    x = enteredArr.find(item => item.abp === current.abp && item.gu === current.gu && item.region === current.region && item.dataType === current.dataType);
                } else if (param === 'abp'){
                    x = enteredArr.find(item => item.abp === current.abp);
                } else if (param === 'gu'){
                    x = enteredArr.find(item => item.abp === current.abp && item.gu === current.gu);
                } else if (param === 'variant'){
                    x = enteredArr.find(item => item.abp === current.abp && item.gu === current.gu && item.region === current.region && item.dataType === current.dataType && item.variant === current.variant);
                }
                if (!x) {
                    return enteredArr.concat([current]);
                } else {
                    return enteredArr;
                }
            }, []);
            return filteredObjectArr;
        },

        chgAbp(){
            this.guBase = [];
            const curAbp = this.curAbp.abp;
            const guBaseArr = [];
            if (curAbp!==''){
                const guFromFormTotal = this.getUniqObjects(this.budgetRequestFormTotalArr, 'gu')
                for (const guItem of this.fullGuBaseList){
                    if (guItem.code.startsWith(curAbp) || parseInt(guItem.abp_owner) === parseInt(curAbp)){
                        guItem.abp = curAbp;
                        guBaseArr.push(guItem)
                    }
                }
                if (guBaseArr.length > 0 && guFromFormTotal.length > 0){
                    for (const gu of guBaseArr){
                        for (const guUniq of guFromFormTotal){
                            if (parseInt(gu.abp) === parseInt(guUniq.abp) && parseInt(gu.code) === parseInt(guUniq.gu)){
                                this.guBase.push(gu);
                            }
                        }
                    }
                }
            }
            if (this.guBase.length>0){
                this.curGu = this.guBase[0];
            }
            this.chgInstitution();
        },
        chgInstitution(){
            this.curRegion = null;
            this.curDataType = null;
            this.curVersion = null;
            this.curRegionVal = null;
            const region = this.curGu.id_region;
            let dataTypeListArr = [];
            if (this.regionListPre.length > 0) {
                for (const reg of this.regionListPre){
                    if (parseInt(reg.code)===parseInt(region)){
                        this.curRegion = reg.code;
                        this.curRegionVal = reg.code +' - '+ reg.name_ru;
                    }
                }
                const dataTypeFromFormTotal = this.getUniqObjects(this.budgetRequestFormTotalArr, 'dataType');
                if (this.dataTypeList.length>0 && dataTypeFromFormTotal.length>0){
                    dataTypeListArr = this.dataTypeList;
                    this.dataTypeList = [];
                    for (const dataType of dataTypeListArr){
                        for (const formTotal of dataTypeFromFormTotal){
                            if (region === formTotal.region && parseInt(this.curGu.abp)===formTotal.abp && this.curGu.code === formTotal.gu && parseInt(dataType.code) === parseInt(formTotal.dataType)){
                                this.dataTypeList.push(dataType);
                            }
                        }
                    }
                    if (this.dataTypeList.length > 0) {
                        this.curDataType = this.dataTypeList[0];
                    }
                }
                this.chgDataType();
            }
        },

        chgDataType(){
            this.curVersion = null;
            this.versionDataTypeList = [];
            const versionFromFormTotal = this.getUniqObjects(this.budgetRequestFormTotalArr, 'variant');
            const dataTypeCode = this.curDataType.code;
            if (this.versionList.length > 0 && versionFromFormTotal.length > 0) {
                const versionListArr = this.versionList;
                for (const version of versionListArr) {
                    for (const formTotal of versionFromFormTotal) {
                        if (this.curRegion === formTotal.region && parseInt(this.curGu.abp) === formTotal.abp && this.curGu.code === formTotal.gu && parseInt(dataTypeCode) === parseInt(formTotal.dataType) && version.variant_uuid === formTotal.variant) {
                            this.versionDataTypeList.push(version);
                        }
                    }
                }
                if (this.versionDataTypeList.length > 0) {
                    this.curVersion = this.versionDataTypeList[0];
                    this.isDisabled = false;
                }
            }
            console.log('curYear: ' +this.curYear.year+ ' year: ' + this.yearInPeriod + ' region: ' + this.curRegion + ' abp: ' + this.curAbp.abp + ' gu: ' + this.curGu.code+ ' dataType: ' + this.curDataType.code + ' variant: ' + this.curVersion.variant_uuid);
        },
        chgVersion(){
            if (Object.keys(this.curYear).length !== 0 && this.yearInPeriod!=null && Object.keys(this.curAbp).length !== 0 && Object.keys(this.curGu).length !== 0 && this.curRegion!=null && Object.keys(this.curDataType).length !== 0 && Object.keys(this.curVersion).length !== 0){
                this.isDisabled = false;
            } else {
                this.isDisabled = true;
            }
        },
        chgParams(){
            this.loadBudgetRequestFormTotal();
            this.collapseAll = true;
            if (this.collapseArr.length > 0){
                for (let i = 0; i < this.collapseArr; i++) {
                    this.collapseArr[i] = this.collapseAll;
                }
            }
        },

        openFilterByRef(refName) {
            const drop = this.$refs.drop;
            drop.show(true);
            const refItem = this.$refs[refName];
            setTimeout(() => refItem.$el.focus(), 100);
        },

        collapseAllFunc() {
            for (let i = 0; i < this.collapseArr.length; i++) {
                this.collapseArr[i] = this.collapseAll;
            }
            this.collapseArr.push(false);
            this.collapseArr.splice(this.collapseArr.length - 1, 1);
            // -------------------------------------------------------------------//
            for (let i = 0; i < this.collapseArrSec.length; i++) {
                this.collapseArrSec[i] = this.collapseAll;
            }
            this.collapseArrSec.push(false);
            this.collapseArrSec.splice(this.collapseArrSec.length - 1, 1);
            // -------------------------------------------------------------------//
            for (let i = 0; i < this.collapseArrThird.length; i++) {
                this.collapseArrThird[i] = this.collapseAll;
            }
            this.collapseArrThird.push(false);
            this.collapseArrThird.splice(this.collapseArrThird.length - 1, 1);
        },
        collapseItems(functItemIndx) {  // ---------Раскрытие первого уровня при нажатии на стрелку вниз
            const rowIndex = functItemIndx;
            this.collapseArr[rowIndex] = !this.collapseArr[rowIndex];
            this.collapseArr.push(false);
            this.collapseArr.splice(this.collapseArr.length - 1, 1);
        },
        collapseItemsSec(functItemIndx) {  // ---------Раскрытие второго уровня при нажатии на стрелку вниз
            const rowIndex = functItemIndx;
            this.collapseArrSec[rowIndex] = !this.collapseArrSec[rowIndex];
            this.collapseArrSec.push(false);
            this.collapseArrSec.splice(this.collapseArrSec.length - 1, 1);
        },
        collapseItemsThird(guItemIndx) {  // ---------Раскрытие четвертого уровня при нажатии на стрелку вниз
            const rowIndex = guItemIndx;
            this.collapseArrThird[rowIndex] = !this.collapseArrThird[rowIndex];
            this.collapseArrThird.push(false);
            this.collapseArrThird.splice(this.collapseArrThird.length - 1, 1);
        },
        //-----Список годов в фильтрации по годам----//
        listOfYears(currentYear){
            this.yearInPeriod = currentYear;
            this.yearsArr = [];
            if (currentYear!==null){
                for (let i=currentYear; i<=(currentYear+2); i++){
                    this.yearsArr.push(i);
                }
            }
            this.getFilterData(this.curYear.year, this.yearInPeriod, this.userId);
        },

        async loadBudgetRequestFormTotal(){
            this.loading = true;
            let funcGrArr = [];
            let abpListArr = [];
            let dictGuListArr = [];
            let programListArr = [];
            let formTotalAgreementArr = [];
            this.funcGrArr = [];
            this.abpListArr = [];
            this.dictGuListArr = [];
            this.formTotalArr = [];
            this.programListArr = [];
            this.formTotalAgreementArr = [];
            try {
                if (this.curYear.year !== '' && this.curRegion !== '' && this.curAbp.abp !== '' && this.curGu.code !== '' && this.curVersion.variant_uuid !== ''){
                    const result = await fetch('/api/explanatory-note/budget-request-form-total/form-total/' + this.curYear.year + '/' + this.curRegion+ '/' + this.curAbp.abp +'/'+ this.curGu.code+ '/'+ this.curVersion.variant_uuid)
                        .then(response => response.json());
                    if (result){
                        funcGrArr = result.funcGr;
                        abpListArr = result.abpList;
                        dictGuListArr = result.dictGuList;
                        programListArr = result.programList.dictEbkFunc;
                        formTotalAgreementArr = result.programList.formTotalAgreement;
                        if (funcGrArr.length > 0) {
                            for (const el of funcGrArr) {
                                this.funcGrArr.push(this.setNameLang(el, 'gr'));
                            }
                        }
                        if (abpListArr.length > 0){
                            for (const el of abpListArr) {
                                this.abpListArr.push(this.setNameLang(el, 'abp'));
                            }
                        }
                        if (dictGuListArr.length > 0) {
                            for (const el of dictGuListArr){
                                this.dictGuListArr.push(this.setNameLang(el, 'code'));
                            }
                        }
                        if (programListArr.length > 0 && formTotalAgreementArr.length > 0){
                            for (const el of programListArr){
                                el.status = '';
                                el.agrNameRu = '';
                                el.agrNameKk = '';
                                el.agrNameEn = '';
                                this.programListArr.push(this.setNameLang(el, 'prg'));
                            }
                            for (const prgs of this.programListArr){
                                for (const el of formTotalAgreementArr){
                                    if (parseInt(el.abp) === parseInt(prgs.abp) && parseInt(el.prg) === parseInt(prgs.prg)) {
                                        prgs.status = el.status;
                                        prgs.agrNameRu = el.nameRu
                                        prgs.agrNameKk = el.nameKk;
                                        prgs.agrNameEn = el.nameEn;
                                    }
                                }
                            }
                        }
                        this.loading = false;
                    } else {
                        this.loading = false;
                        this.makeToast('Статус поиска', 'По данному запросу ничего не найдено');
                    }
                }
            } catch (error){
                this.loading = false;
                this.makeToast('Статус поиска', 'По данному запросу ничего не найдено');
            }
        },
        editBtn(item){
            if (item){
                this.openAddTab = true;
                this.moduleTabSwitch = false;
                this.prgItems = item;
            }
        },
        moduleTabSwitches(item){
            if (item) {
                this.openAddTab = !item;
                this.moduleTabSwitch = item;
            }
        },

        async deleteClk(item){
            console.log('DELETE_item: ' + JSON.stringify(item));
            const params = {
                'id': parseInt(item.id),
                'gr': parseInt(item.gr),
                'pgr': parseInt(item.pgr),
                'abp': parseInt(item.abp),
                'prg': parseInt(item.prg),
                'status': parseInt(item.status)
            };
            try {
                const url = '/api/explanatory-note/budget-request-form-total/remove';
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(params) // массив будет направлен на kotlin
                });
                const result = await response.json();
                console.log('result: ' + JSON.stringify(result));
                if ((response.status === 200) && (result === true)) {

                }
            } catch {
                this.makeToast('Очистка заявки', 'Ошибка удаления заявки');
            }
        },

        setNameLang(obj, codeName) {
            if (obj === null) { return null; }
            let txt = obj['name_' + this.$i18n.locale];
            if (txt === undefined) { txt = obj.name_ru; }
            if (codeName !== undefined && codeName !== null && obj[codeName] !== undefined) {
                txt = obj[codeName] + ' - ' + txt;
            }
            const el = Object.assign({}, obj);
            el.name = txt;
            return el;
        },

        makeToast(title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                autoHideDelay: 5000,
                appendToast: true
            });
        }, // сообщение с ошибкой
    }
}
</script>

<style lang="scss">

.enf-modal {
    .modal-title {
        font-size: 14px;
        font-weight: 700;
        color: #1C2A3E;
        border-bottom: none;
    }



    legend {
        margin-bottom: 10px;
    }

    .form-control {
        min-height: 360px;
        border: 1px solid #BCD2E0;
        border-radius: 5px;
        background-color: #fff;
        font-size: 14px;
        font-weight: 500;
    }
}

.filter-area {
}

.explanatory-note {
    .enf-form-group {
        position: relative;
        .text-area-block {
            min-height: 40px;
            border: 1px solid #BCD2E0;
            border-radius: 5px;
            background-color: #fff;
            padding: 5px 40px 5px 10px;
            line-height: 1.4;
            font-size: 14px;
            font-weight: 500;

            .enf-placeholder {
                color: #6087A0;
            }
        }
        .text-area-block-empty {
            min-height: 40px;
            border: 1px solid #EE0505;
            border-radius: 5px;
            background-color: #fff;
            padding: 5px 40px 5px 10px;
            line-height: 1.4;
            font-size: 14px;
            font-weight: 500;

            .enf-placeholder {
                color: #6087A0;
            }
        }
        .icon-pencil-edit {
            position: absolute;
            top: 12px;
            right: 15px;
            color: #2196F3;
            cursor: pointer;
        }
    }

    .enf-top-info {
        position: absolute;
        top: 40px;
        right: 10px;
        z-index: 1005;
        .duplicate-radio {
            margin-top: 6px;
            display: flex;
            align-content: center;
            .custom-radio{
                text-transform: uppercase;
            }
            .enf-label {
                color: #1C2A3E;
                font-size: 14px;
                margin-top: 6px;
                font-weight: 700;
                margin-right: 20px;
            }
        }
    }

    .add-program {
        padding: 15px 20px;
        display: inline-flex;
        font-size: 14px;
        align-content: center;
        color: #2196F3 !important;
        cursor: pointer;

        i {
            margin-right: 15px;
            font-size: 20px;
        }

        b {
            text-decoration: underline;
        }
    }

    .td-link:hover {
        color: #2196F3 !important;
        cursor: pointer;
    }

    .more {
        .text-danger {
            button {
                color: #dc3545 !important;
            }
        }
    }

    .explanatory-note-form {
        .enf-title {
            padding: 10px 20px;
            background-color: #fff;
            font-size: 14px;
            font-weight: 700;
            display: flex;
            align-content: center;
            justify-content: flex-start;

            i {
                color: #2196F3;
                font-size: 17px;
                margin-left: 10px;
                cursor: pointer;
            }
        }

        .enf-row {
            margin: 0;
            padding: 10px;
            border-top: 1px solid #D3DBE3;
            border-bottom: 1px solid #D3DBE3;
        }

        .form-group {
            margin: 0;
        }

        .form-row {
            margin: 0;
            align-items: flex-start;
        }
    }

    .table-container {
        .add-indicator {
            .icon-plus-circle {
                font-size: 20px;
                color: #2196F3;
            }

            span {
                color: #2196F3;
                display: inline-flex;
                align-content: center;
                cursor: pointer;

                b {
                    text-decoration: underline;
                }

                i {
                    margin-right: 10px;
                }
            }
        }

        .enf-row {
            margin: 0;
            padding: 0;
            border-top: none;
            border-bottom: none;
        }

    }


}
</style>