<template>
    <div class="filter-breadcrumb">
        <span class="item-breadcrumb" @click="openFilterByRef('yearPeriodRef')">
            {{ curYear.name }}
        </span>
        <span class="item-breadcrumb" @click="openFilterByRef('curYearRef')">
            {{ yearInPeriod }}
        </span>
        <template v-if="curAbp">
            <span class="item-breadcrumb" @click="openFilterByRef('abpRef')">
                {{ curAbp.abp }}-АБП
            </span>
        </template>
        <template v-if="curGu">
            <span class="item-breadcrumb" @click="openFilterByRef('guRef')">
                {{ curGu.code }}-ГУ
            </span>
        </template>
        <template v-if="curDataType">
            <span class="item-breadcrumb" @click="openFilterByRef('dataTypeRef')">
                {{ curDataType.name_ru }}
            </span>
        </template>
        <template v-if="curVersion">
            <span class="item-breadcrumb" @click="openFilterByRef('versionRef')">
                {{ curVersion.name_ru }}
            </span>
        </template>
        <template v-if="curPrg">
            <span class="item-breadcrumb">
                {{ curPrg.prg }} - {{ curPrg.name_ru }}
            </span>
        </template>
    </div>
</template>
<script>
export default {
    name: 'CBreadCrumbs',
    props: {
        curYear: {
            type: Object,
            required: false,
            default: () => {}
        },
        yearInPeriod: {
            type: Number,
            required: false,
            default: null
        },
        curAbp: {
            type: Object,
            required: false,
            default: () => {}
        },
        curGu: {
            type: Object,
            required: false,
            default: () => {}
        },
        curDataType: {
            type: Object,
            required: false,
            default: () => {}
        },
        curVersion: {
            type: Object,
            required: false,
            default: () => {}
        },
        curPrg: {
            type: Object,
            required: false,
            default: () => {}
        }
    },
    mounted(){
    },
    data(){
        return {
        }
    },
    methods: {
        openFilterByRef(refName) {
            this.$emit('openFilterByRef', refName);
        }
    }
};

</script>
<style scoped>

</style>
