<template>
    <div class="table-container">
        <loading
            :active="loading"
            is-full-screen
            spinner="bar-fade-scale"
            color="#6495ED"
        />
        <div class="b-table-sticky-header table-responsive-true">
            <table class="table b-table table-bordered b-table-no-border-collapse">
                <thead>
                <tr>
                    <th rowspan="2" class="toggle-show" style="width: 67px">
                        <button type="button" class="btn btn-secondary" @click="collapseAll = !collapseAll;">
                            <i class="icon icon-chevron-circle" v-if="collapseAll"></i>
                            <i class="icon icon-chevron-circle icon-rotate-180" v-else></i>
                        </button>
                    </th>
                    <th colspan="2">НАИМЕНОВАНИЕ</th>
                </tr>
                </thead>
                <tbody>
                <template v-for="(prgItem, prgItemIndx) of programsArr">
                    <tr :key="'prgItemIndx'+prgItemIndx" class="table-info">
                        <td class="toggle-show">
                            <button type="button" class="btn btn-secondary" @click="collapseItems(prgItemIndx)">
                                <i class="icon icon-chevron-circle" v-if="collapseArr[prgItemIndx]"></i>
                                <i class="icon icon-chevron-circle icon-rotate-180" v-else></i>
                            </button>
                        </td>
                        <td colspan="8">{{ prgItem.name_ru }}</td>
                    </tr>
                    <template v-if="!collapseArr[prgItemIndx]">
                        <template v-for="(goalItem, goalItemIndex) of goalsArr">
                            <template v-if="goalItem!==null && goalItem.forecastId===prgItem.forecastId">
                                <tr :key="`goalItemIndex${goalItemIndex}_${prgItemIndx}`" class="table-info" style="background-color: rgb(236, 247, 255);">
                                    <td></td>
                                    <td class="toggle-show" style="width: 67px;">
                                        <button type="button" class="btn btn-secondary" @click="collapseItemsSec(goalItemIndex)">
                                            <i class="icon icon-chevron-circle" v-if="collapseArrSec[goalItemIndex]"></i>
                                            <i class="icon icon-chevron-circle icon-rotate-180" v-else></i>
                                        </button>
                                    </td>
                                    <td colspan="7">{{ goalItem.name_ru }}</td>
                                </tr>
                                <template v-if="!collapseArrSec[goalItemIndex]">
                                    <template v-for="(indicatorItem, indicatorItemIndex) of indicatorsArr">
                                        <template v-if="indicatorItem!==null && parseInt(indicatorItem.forecastId)===parseInt(goalItem.forecastId)">
                                            <tr :key="`indicatorItemIndex${prgItemIndx}_${goalItemIndex}_${indicatorItemIndex}`">
                                                <td colspan="2"></td>
                                                <td>{{ indicatorItem.name_ru }}: {{indicatorItem.planValue.toLocaleString()}} <template v-if="indicatorItem.planValue">тыс. тенге</template></td>
                                            </tr>
                                            <tr :key="`indicatorItemIndexes${prgItemIndx}_${goalItemIndex}_${indicatorItemIndex}`" style="background-color: rgb(245 246 247)">
                                                <td colspan="2"></td>
                                                <td>

                                                    <b-row class="enf-row">
                                                        <b-col cols="12" md="6">
                                                            <b-form-group class="enf-form-group" label-cols="3">
                                                                <template #label>{{ description_kk }} :</template>
                                                                <div v-bind:class="[allowable(indicatorItem.nameKk) ? descriptionNotEmpty : descriptionEmpty]" style="text-align: justify"><span
                                                                    class="enf-placeholder" v-if="!indicatorItem.nameKk">Введите описание {{ description_kk }}</span>
                                                                    <template v-if="indicatorItem.nameKk.length<200">{{ indicatorItem.nameKk }}</template>
                                                                    <template v-else>{{ indicatorItem.nameKk.slice(0, 200) }}...</template>
                                                               </div>
                                                                <template v-if="editAccess">
                                                                    <i class="icon icon-pencil-edit" @click="editDescription('kk', true, indicatorItem, goalItem, prgItem)"></i>
                                                                </template>
                                                                <template v-else>
                                                                    <i class="icon icon-eye-open" @click="editDescription('kk', true, indicatorItem, goalItem, prgItem)"></i>
                                                                </template>
                                                            </b-form-group>
                                                        </b-col>
                                                        <b-col cols="12" md="6">
                                                            <b-form-group class="enf-form-group" label-cols="3">
                                                                <template #label>{{ description_ru }}</template>
                                                                <div v-bind:class="[allowable(indicatorItem.nameRu) ? descriptionNotEmpty : descriptionEmpty]" style="text-align: justify"><span
                                                                    class="enf-placeholder" v-if="!indicatorItem.nameRu">Введите описание {{ description_ru }}</span>
                                                                    <template v-if="indicatorItem.nameRu.length<200">{{ indicatorItem.nameRu }}</template>
                                                                    <template v-else>{{ indicatorItem.nameRu.slice(0, 200) }}...</template>
                                                                </div>
                                                                <template v-if="editAccess">
                                                                    <i class="icon icon-pencil-edit" @click="editDescription('ru', true, indicatorItem, goalItem, prgItem)"></i>
                                                                </template>
                                                                <template v-else>
                                                                    <i class="icon icon-eye-open" @click="editDescription('ru', true, indicatorItem, goalItem, prgItem)"></i>
                                                                </template>
                                                            </b-form-group>
                                                        </b-col>
                                                    </b-row>
                                                </td>
                                            </tr>
                                        </template>
                                    </template>
                                </template>
                            </template>
                        </template>
                    </template>
                </template>
                </tbody>
            </table>
        </div>
        <b-modal
            v-model="modalVisible"
            modal-class="enf-modal"
            size="lg"
            centered
            :title="modalTitle"
            hide-footer
        >
            <b-form-group>
                <template #label>{{ description_part }} :</template>
                <template v-if="editAccess">
                    <b-form-textarea placeholder="введите описание" v-model="descriptionArea" style="text-align: justify"></b-form-textarea>
                </template>
                <template v-else>
                    <b-form-textarea readonly v-model="descriptionArea" style="text-align: justify"></b-form-textarea>
                </template>
            </b-form-group>
            <template v-if="editAccess">
                <b-button variant="primary" @click="addDescriptionForm">Сохранить</b-button>
            </template>
            <b-button variant="light" @click="closeDescriptionForm">Отменить</b-button>
        </b-modal>
    </div>
</template>

<script>
    import {makeToast} from "@/modules/budget/bip/bip-types";
    import VueElementLoading from 'vue-element-loading';

    export default {
        name: 'ExplanatoryNoteAddTable',
        components: {
            'loading': VueElementLoading
        },
        props: {
            prgItems: {
                type: Object,
                required: true
            },
            curYear: {
                type: Object,
                required: false,
                default: () => {}
            },
            yearInPeriod: {
                type: Number,
                required: false,
                default: null
            },
            curRegion: {
                type: String,
                required: true
            },
            curAbp: {
                type: Object,
                required: false,
                default: () => {}
            },
            curGu: {
                type: Object,
                required: false,
                default: () => {}
            },
            curDataType: {
                type: Object,
                required: false,
                default: () => {}
            },
            curVersion: {
                type: Object,
                required: false,
                default: () => {}
            },
            userId: {
                type: String,
                required: false,
                default: () => {}
            },
            needSave: {
                type: Boolean,
                required: false
            },
            editAccess: {
                type: Boolean,
                required: false
            }
        },
        watch: {
            needSave: function (value){
                if (value===true) {
                    this.$emit('needSaved', false);
                    const arrIndicator = onlyFilledFields(this.indicatorsArr);
                    this.$emit('indicatorsArr', arrIndicator);
                }
                function onlyFilledFields(indicatorsArr){
                    const arr = [];
                    if (indicatorsArr.length>0){
                        for (const indicator of indicatorsArr){
                            arr.push(indicator);
                        }
                    }
                    return arr;
                }
            }
        },
        data() {
            return {
                description_ru: 'на русском',
                description_kk: 'на казахском',
                descriptionEmpty: 'text-area-block-empty',
                descriptionNotEmpty: 'text-area-block',
                ru: 'ru',
                kk: 'kk',
                targetItems: {
                    indicator: null,
                    descrId: null,
                    lang: null,
                    forecastId: null,
                    prgId: null,
                    goalId: null,
                    indicatorId: null
                },
                loading: false,
                collapseAll: true,
                saveTarget: true,
                modalVisible: false,
                modalTitle: '',
                description_part: '',
                descriptionArea: '',
                forecast_data: [],
                programsArr: [],
                goalsArr: [],
                forecastYears: [],
                indicatorsArr: [],
                collapseArr: [],
                collapseArrSec: [],
                collapseArrThird: []
            }
        },
        async created(){
            await this.getListOfIndicators();
            this.$watch('collapseAll', this.collapseAllFunc);
            console.log('editAccess: ' + JSON.stringify(this.editAccess));
        },
        methods: {
            allowable(descItem){
                if (descItem.length > 0 && this.editAccess){
                    return true;
                } else if (!this.editAccess){
                    return true;
                }
                return false;
            },
            async getListOfIndicators() {
                this.loading = true;
                const requestNoteObj = this.generateRequestNote();
                try {
                    let response = [];
                    response = await fetch('/api/explanatory-note/budget-request-note/get_forecast/' + requestNoteObj.abp + '/' + requestNoteObj.prg+'/'+requestNoteObj.gu+'/'+requestNoteObj.dataTypeId+'/'+requestNoteObj.year+'/'+requestNoteObj.curYear+'/'+requestNoteObj.region+'/'+requestNoteObj.variant);
                    response = await response.json();
                    if (response) {
                        this.extractSmallArrays(response);
                        this.forecast_data = response;
                        this.collapseHide(this.collapseAll);
                        await this.getNoteTargetIndicatorFromDB();
                        this.loading = false;
                    }
                } catch (error) {
                    makeToast(this, 'danger', 'Ошибка загрузки Целевого индикатора', error.toString());
                    this.loading = false;
                }
            },

            async getNoteTargetIndicatorFromDB(){
                const requestNoteObj = this.generateRequestNote();
                try {
                    let response = [];
                    response = await fetch('/api/explanatory-note/note-target-indicator/get/'+requestNoteObj.abp+'/'+requestNoteObj.prg+'/'+requestNoteObj.gu+'/'+requestNoteObj.dataTypeId+'/'+requestNoteObj.year+'/'+requestNoteObj.curYear+'/'+requestNoteObj.region+'/'+requestNoteObj.variant);
                    response = await response.json();
                    if (response) {
                        if (response.length>0){
                            this.setTargetIndicatorFromDB(response);
                        }
                    }
                } catch (error) {
                    makeToast(this, 'danger', 'Ошибка загрузки "Целевых индикаторов"', error.toString());
                    this.loading = false;
                }
            },

            setTargetIndicatorFromDB(response){
                if (response.length>0 && this.indicatorsArr.length>0){
                    for (const indicator of this.indicatorsArr){
                        for (const resp of response){
                            if (indicator.forecastId===resp.forecastId){
                                indicator.nameKk=resp.nameKk;
                                indicator.nameRu=resp.nameRu;
                            }
                        }
                    }
                }
            },

            closeDescriptionForm(){
                this.modalVisible = false;
                this.descriptionArea = '';
            },
            editDescription(lang, show, descrItem, goalItem, prgItem) {
                if (show) {
                    this.modalVisible = show;
                    if (lang === this.ru) {
                        this.description_part = getDescription(this.description_ru);
                        this.targetItems.forecastId = prgItem.forecastId;
                        this.targetItems.prgId = prgItem.id;
                        this.targetItems.goalId = goalItem.id;
                        this.targetItems.indicatorId = descrItem.id;
                        this.targetItems.lang = lang;
                        this.descriptionArea = descrItem.nameRu;
                    } else if (lang === this.kk){
                        this.description_part = getDescription(this.description_kk);
                        this.targetItems.forecastId = prgItem.forecastId;
                        this.targetItems.prgId = prgItem.id;
                        this.targetItems.goalId = goalItem.id;
                        this.targetItems.indicatorId = descrItem.id;
                        this.descriptionArea = descrItem.nameKk;
                        this.targetItems.lang = lang;
                    }
                    this.modalTitle = descrItem.name_ru;
                }

                function getDescription(word){
                    const firstPart = word.slice(0, 1);
                    const secondPart = word.slice(1, word.length);
                    return (firstPart.toUpperCase())+secondPart;
                }
            },
            addDescriptionForm(){
                const targetIndicator = this.requestTargetIndicatorObject();
                const language = {
                    'kk': this.kk,
                    'ru': this.ru
                }
                if (this.targetItems.lang === this.kk){
                    targetIndicator.nameKk = this.descriptionArea;
                    targetIndicator.lang = this.kk;
                }
                if (this.targetItems.lang === this.ru){
                    targetIndicator.nameRu = this.descriptionArea;
                    targetIndicator.lang = this.ru;
                }
                targetIndicator.forecastId = this.targetItems.forecastId;
                targetIndicator.prgId = this.targetItems.prgId;
                targetIndicator.goalId = this.targetItems.goalId;
                targetIndicator.indicatorId = this.targetItems.indicatorId;
                setValuest(this.indicatorsArr, targetIndicator, language);
                this.closeDescriptionForm();

                function setValuest(indicatorsArr, targetIndicator, language){
                    const arr = [];
                    if (indicatorsArr.length > 0 && targetIndicator){
                        for (const indicator of indicatorsArr){
                            if (indicator.id == targetIndicator.indicatorId){
                                if (targetIndicator.lang === language.kk) {
                                    indicator.nameKk = targetIndicator.nameKk;
                                } else if (targetIndicator.lang === language.ru) {
                                    indicator.nameRu = targetIndicator.nameRu;
                                }
                                indicator.programId = targetIndicator.prgId;
                                indicator.goalId = targetIndicator.goalId;
                                indicator.indicatorId = targetIndicator.indicatorId;
                            }
                        }
                    }
                    return arr;
                }
            },

            requestTargetIndicatorObject(){
                return {
                    id: null,
                    nameKk: '',
                    nameRu: '',
                    forecastId: null,
                    prgId: null,
                    goalId: null,
                    indicatorId: null,
                    lang: ''
                }
            },

            collapseHide(collapseAll){
                const prgList = this.programsArr;
                if (prgList.length>0){
                    for (let i = 0; i < prgList.length; i++){
                        this.collapseArr[i] = collapseAll;
                    }
                }
            },
            requestNoteObject(){
                return {
                    id: null,
                    abp: '',
                    prg: '',
                    gu: '',
                    dataTypeId: '',
                    year: '',
                    curYear: '',
                    region: '',
                    variant: '',
                    userId: '',
                    requestNoteDescriptionId: ''
                }
            },
            generateRequestNote(){
                const requestNoteObject = this.requestNoteObject();
                requestNoteObject.abp = this.curAbp.abp;
                requestNoteObject.prg = this.prgItems.prg;
                requestNoteObject.gu = this.curGu.code;
                requestNoteObject.dataTypeId = this.curDataType.code;
                requestNoteObject.year = this.yearInPeriod;
                requestNoteObject.curYear = this.curYear.year;
                requestNoteObject.region = this.curRegion;
                requestNoteObject.variant = this.curVersion.variant_uuid;
                requestNoteObject.userId = this.userId;
                return requestNoteObject;
            },

            extractSmallArrays(bigArray){
                this.programsArr = [];
                this.goalsArr = [];
                this.forecastYears = [];
                this.indicatorsArr = [];
                if (bigArray.length>0){
                    for (const arr of bigArray){
                        const forecast = arr.forecastEntityList;
                        forecast.program.forecastId = forecast.id;
                        this.programsArr.push(forecast.program);

                        forecast.goals.forecastId = forecast.id;
                        this.goalsArr.push(forecast.goals);

                        const yearObj = getYearObjByParam(forecast.years, this.yearInPeriod);
                        forecast.indicator.forecastId = forecast.id;
                        forecast.indicator.planValue = Object.keys(yearObj).length !== 0 ? yearObj.plan : '';
                        forecast.indicator.year = Object.keys(yearObj).length !== 0 ? yearObj.year : '';
                        forecast.indicator.programId = '';
                        forecast.indicator.goalId = '';
                        forecast.indicator.indicatorId = '';
                        forecast.indicator.nameRu = '';
                        forecast.indicator.nameKk = '';
                        this.indicatorsArr.push(forecast.indicator);
                    }
                }

                function getYearObjByParam(yearsArr, year){
                    let yearObj = {};
                    if (yearsArr.length > 0){
                        for (const yearItem of yearsArr) {
                            if (yearItem.year === year) {
                                yearObj = yearItem;
                            }
                        }
                    }
                    return yearObj;
                }
            },

            collapseAllFunc() {
                for (let i = 0; i < this.collapseArr.length; i++) {
                    this.collapseArr[i] = this.collapseAll;
                }
                this.collapseArr.push(false);
                this.collapseArr.splice(this.collapseArr.length - 1, 1);
                // -------------------------------------------------------------------//
                for (let i = 0; i < this.collapseArrSec.length; i++) {
                    this.collapseArrSec[i] = this.collapseAll;
                }
                this.collapseArrSec.push(false);
                this.collapseArrSec.splice(this.collapseArrSec.length - 1, 1);
            },
            collapseItems(functItemIndx) {  // ---------Раскрытие первого уровня при нажатии на стрелку вниз
                const rowIndex = functItemIndx;
                this.collapseArr[rowIndex] = !this.collapseArr[rowIndex];
                this.collapseArr.push(false);
                this.collapseArr.splice(this.collapseArr.length - 1, 1);
            },
            collapseItemsSec(functItemIndx) {  // ---------Раскрытие второго уровня при нажатии на стрелку вниз
                const rowIndex = functItemIndx;
                this.collapseArrSec[rowIndex] = !this.collapseArrSec[rowIndex];
                this.collapseArrSec.push(false);
                this.collapseArrSec.splice(this.collapseArrSec.length - 1, 1);
            },

            makeToast(title, tostbody) {
                this.$bvToast.toast(tostbody, {
                    title: title,
                    autoHideDelay: 5000,
                    appendToast: true
                });
            } // сообщение с ошибкой
        }
    }
</script>

<style>
</style>